import { fetchApi } from '../fetchApi/fetchApi';
import React, { useEffect, useState } from 'react';
import '../assets/css/info.css';
import ProductGrid from './Productos/ProductGrid';
import Spinner from '../components/Spinner';
import { useRouteMatch } from "react-router-dom";

const Catalogo = () => {

    interface MatchParams {
        categoria: string;
    }
    const match = useRouteMatch<MatchParams>('/tienda/:categoria')

    const [saborizantes, setSaborizantes] = useState([])
    const [colorantes, setColorantes] = useState([])
    const [decoracion, setDecoracion] = useState([])
    const [materiasPrimas, setMateriasPrimas] = useState([])
    const [fondant, setFondant] = useState([])
    const [charolas, setCharolas] = useState([])
    const [moldes, setMoldes] = useState([])
    const [utensilios, setUtensilios] = useState([])

    const [search, setSearch] = useState('');
    const [categoria, setCategoria] = useState('')

    useEffect(() => {
        console.log(`Buscando ${match?.params.categoria || 'todos'}`);
        if (match?.params.categoria !== undefined) {
            setCategoria(match?.params.categoria.toLocaleLowerCase())
        } else {
            setCategoria('todos')
        }
    }, [match])

    useEffect(() => {
        fetchApi('productos/saborizantes', 'GET')
            .then(response => {
                if (response != null) {
                    setSaborizantes(response)
                }
            })
        fetchApi('productos/colorantes', 'GET')
            .then(response => {
                if (response != null) {
                    setColorantes(response)
                }
            })
        fetchApi('productos/materias_primas', 'GET')
            .then(response => {
                if (response != null) {
                    setMateriasPrimas(response)
                }
            })
        fetchApi('productos/decoracion', 'GET')
            .then(response => {
                if (response != null) {
                    setDecoracion(response)
                }
            })
        fetchApi('productos/fondant', 'GET')
            .then(response => {
                if (response != null) {
                    setFondant(response)
                }
            })
        fetchApi('productos/charolas', 'GET')
            .then(response => {
                if (response != null) {
                    setCharolas(response)
                }
            })
        fetchApi('productos/moldes', 'GET')
            .then(response => {
                if (response != null) {
                    setMoldes(response)
                }
            })
        fetchApi('productos/utensilios', 'GET')
            .then(response => {
                if (response != null) {
                    setUtensilios(response)
                }
            })
    }, []);

    return (
        <div>
            <div className="container">
                <input value={search} className='input-search form-control' placeholder='Buscar' onChange={e => setSearch(e.target.value)} />
            </div>
            {utensilios.length === 0 ? (<Spinner />) :
                (categoria === '' || categoria === undefined) ? <Spinner /> : ''
            }
            {saborizantes.length > 0 && (categoria === 'todos' || categoria === 'saborizantes') &&
                <ProductGrid search={search} title={'Saborizantes'} items={saborizantes}></ProductGrid>
            }
            {colorantes.length > 0 && (categoria === 'todos' || categoria === 'colorantes') &&
                <ProductGrid search={search} title={'Colorantes'} items={colorantes}></ProductGrid>
            }
            {materiasPrimas.length > 0 && (categoria === 'todos' || categoria === 'materiasprimas') &&
                <ProductGrid search={search} title={'Materias Primas'} items={materiasPrimas}></ProductGrid>
            }
            {decoracion.length > 0 && (categoria === 'todos' || categoria === 'decoracion') &&
                <ProductGrid search={search} title={'Decoración'} items={decoracion}></ProductGrid>
            }
            {fondant.length > 0 && (categoria === 'todos' || categoria === 'fondand') &&
                <ProductGrid search={search} title={'Fondant'} items={fondant}></ProductGrid>
            }
            {moldes.length > 0 && (categoria === 'todos' || categoria === 'moldes') &&
                <ProductGrid search={search} title={'Moldes'} items={moldes}></ProductGrid>
            }
            {charolas.length > 0 && (categoria === 'todos' || categoria === 'charolas') &&
                <ProductGrid search={search} title={'Charolas'} items={charolas}></ProductGrid>
            }
            {utensilios.length > 0 && (categoria === 'todos' || categoria === 'utensilios') &&
                <ProductGrid search={search} title={'Utensilios'} items={utensilios}></ProductGrid>
            }
        </div>
    )
}

export default Catalogo;