import React from "react";
import prod03 from '../assets/img/prod-03.jpg';
import coloranteAmarilloHuevo from '../assets/img/colorntes-amarillo-huevo-polvo.jpg';
import coloranteCarameloLiquido from '../assets/img/colorntes-caramelo-liquido.jpg';
import coloranteDioxidoTitanio from '../assets/img/colorntes-dioxido-titanio.jpg';
import '../assets/css/info.css';

const Saborizantes = (props: any) => {

    return (
        <div className="container m-t-40">
            <div className="row p-b-148">
                <div className="col-md-7 col-lg-8">
                    <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md info_saborizantes">
                        <h5 className="mtext-111 cl2">
                            Colorchef Líquido
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                            Por su presentación líquida se usa preferentemente con aerógrafo.
                        </p>
                        <p className="stext-113 cl6 text-blue">
                            Presentación: Envases de 60 ml y 250 ml.
                        </p>

                        <h5 className="mtext-111 cl2">
                            Colorchef Gel
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                            Es de apariencia semilíquida, que con su dosificador especial nos permite un mejor uso y control de la cantidad a emplear.
                        </p>
                        <p className="stext-113 cl6 text-blue">
                            Presentación: Envases de 60 ml y 250 ml.
                        </p>

                        <h5 className="mtext-111 cl2">
                            Colorchef Pasta
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                            Es el más concentrado de nuestra línea; da un color profundo.
                        </p>
                        <p className="stext-113 cl6 text-blue">
                            Presentación: Envases de 50 g y 325 g.
                        </p>

                        <h5 className="mtext-111 cl2">
                            Colorchef Líquido
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                            Por su presentación líquida se usa preferentemente con aerógrafo.
                        </p>
                        <p className="stext-113 cl6 text-blue">
                            Presentación: Envases de 60 ml y 250 ml.
                        </p>

                        <br></br>

                        <h5 className="mtext-111 cl2 text-black">
                            Colorchef Líquido Metálico
                        </h5>
                        <p className="stext-113 cl6 text-blue">
                            Presentación: Envases de 60 ml y 250 ml.
                        </p>

                        <br></br>
                    </div>
                </div>

                <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
                    <div className="how-bor1 ">
                        <div className="hov-img0">
                            <img src={prod03} alt="IMG" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-b-148">
                    <div className="order-md-2 col-md-7 col-lg-8 p-b-30">
                        <div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md info_saborizantes">
                        <h5 className="mtext-111 cl2">
                                Color Amarillo Huevo en Polvo
							</h5>
                            <p className="stext-113 cl6 m-b-0 text-black">
                                Color en polvo, el cual se puede hidratar y agregar en las mezclas y/o batidos del pan, para darles ese tono deseado de yema de huevo natural. Se utiliza en cualquier tipo de panes y batidos.
                            </p>
                            <p className="stext-113 cl6 text-blue">
                                Presentación: Envases de 4 kg y 1 kg.
                            </p>
                        </div>
                    </div>
                    <div className="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
                        <div className="how-bor2">
                            <div className="hov-img0">
                                <img src={coloranteAmarilloHuevo} alt="IMG" />
                            </div>
                        </div>
                    </div>
                </div>
            <div className="row p-b-148">
                    <div className="col-md-7 col-lg-8">
                        <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md info_saborizantes">
                        <h5 className="mtext-111 cl2">
                                Color Caramelo Líquido
							</h5>
                            <p className="stext-113 cl6 m-b-0 text-black">
                                Colorante natural líquido obscuro que se recomienda utilizar en panes o pasteles que requieren de una tonalidad difícil de obtener con chocolate o cocoa. Se utiliza en pan negro tipo alemán, pingüinos, chocoroles, muffins, etc.
                            </p>
                            <p className="stext-113 cl6 text-blue">
                                Presentación: Envases de 5 kg y 1.3 kg.
                            </p>
                        </div>
                    </div>

                    <div className="col-11 col-md-5 col-lg-4 m-lr-auto  p-b-30">
                        <div className="how-bor1 ">
                            <div className="hov-img0">
                                <img src={coloranteCarameloLiquido} alt="IMG" />
                            </div>
                        </div>
                    </div>
                </div>
            <div className="row p-b-148">
                    <div className="order-md-2 col-md-7 col-lg-8 p-b-30">
                        <div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md info_saborizantes">
                        <h5 className="mtext-111 cl2">
                            Colorante Blanco Dióxido de Titanio
							</h5>
                            <p className="stext-113 cl6 m-b-0 text-black">
                                Mezcla homogénea de pigmento blanco libre de materia extraña. Que se utiliza para dar diferentes tonalidades en algunos productos alimenticios.
                            </p>
                            <p className="stext-113 cl6 text-blue">
                                Presentación: Envase de 4l, 1l y 125ml.
                            </p>
                        </div>
                    </div>
                    <div className="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
                        <div className="how-bor2">
                            <div className="hov-img0">
                                <img src={coloranteDioxidoTitanio} alt="IMG" />
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Saborizantes;