import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import ImageSlide01 from '../../assets/img/slide-01.jpg';
import ImageSlide02 from '../../assets/img/slide-02.jpg';
import ImageSlide03 from '../../assets/img/slide-03.jpg';
import logo from '../../assets/images/logos/logo.png'
import '../../assets/css/jumbotron.css';

const Jumbotron: React.FC = () => {
    return (
        <Carousel fade={true} interval={4000}>
            <Carousel.Item>
                <img className="d-block w-100" src={ImageSlide01} alt="First slide" />
                <Carousel.Caption>
                    <img src={logo} alt=""/>
                    <p>El mejor sabor</p>
                    <h3>PARA TU PAN</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={ImageSlide02} alt="Second slide" />
                <Carousel.Caption>
                    <img src={logo} alt="" />
                    <p >El toque perfecto para</p>
                    <h3>TU DECORACIÓN</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={ImageSlide03} alt="Third slide" />
                <Carousel.Caption>
                    <img src={logo} alt="" />
                    <p>Moldes y Charolas</p>
                    <h3>PARA TUS CREACIONES</h3>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
}

export default Jumbotron;