import { ADD_TO_CART, REMOVE_FROM_CART, SHOW_CART, HIDE_CART, INCREASE_QUANTITY, DECREASE_QUANTITY } from './action-types/cart-actions'

export const addToCart = (product) => {

    return {
        type: ADD_TO_CART,
        payload: {
            product,
        }
    }
};

export const deleteItem = (product) => {
    return {
        type: REMOVE_FROM_CART,
        payload: {
            product,
        }
    }
}

export const emptyCart = () => {
    return {
        type: SHOW_CART
    }
}

export const showCart = () => {
    return {
        type: SHOW_CART
    }
}

export const hideCart = () => {
    return {
        type: HIDE_CART
    }
}

export const increaseItemQuantity = (product) => {
    return {
        type: INCREASE_QUANTITY,
        payload: {
            product,
        }
    }
}

export const decreaseItemQuantity = (product) => {
    return {
        type: DECREASE_QUANTITY,
        payload: {
            product,
        }
    }
}

 
