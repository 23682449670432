import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './styles.css';
import { fetchQuotation } from '../../fetchApi/fetchApi';
import { Link } from "react-router-dom";
import Spinner from '../../components/Spinner';

const CheckoutAddress = () => {

    const cartState = useSelector((state: any) => {
        return state.cart;
    });

    const [spinner, showSpinner] = useState(false);
    const [selectedRate, setSelecteRate] = useState(0);

    const [rates, setRates] = useState([{
        "rate_id": "",
        "provider": "",
        "provider_image_75": "",
        "currency": "",
        "days": 0,
        "amount": "00.00",
        "account_id": "",
        "servicelevel": {
            "name": "",
            "token": ""
        },
        "status": "HOLD"
    }]);

    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [street_one, setStreetOne] = useState('');
    const [street_two, setStreetTwo] = useState('');
    const [city, setCity] = useState('');
    const [zip_code, setZipCode] = useState('');
    const [state, setState] = useState('');
    // const [country, setCountry] = useState('');
    const [phone, setPhone] = useState('');
    const [mail, setMail] = useState('');
    const [address_to, setAddressTo] = useState({});
    
    const postQuotation = (event:any) => {

        event.preventDefault()
        showSpinner(true)

        var client_address = {
            'name': name,
            'company': company,
            'street1': street_one,
            'street2': street_two,
            'city': city,
            'zip': zip_code,
            'state': state,
            'country': 'MX',
            'phone': phone,
            'mail': mail,
            'email': mail,
        }

        setAddressTo(client_address);

        fetchQuotation(client_address)
        .then(response => {
            console.log(response)
            setRates(response.rates)
            showSpinner(false)
        })
            
        // setRates(dummyResponse.rates);
            
    }

    const setShipmentRate = (e:any) => {
        setSelecteRate(e);
    }

    const getTotal = () => {

        var subTotal = Number(rates[selectedRate].amount);

        cartState.itemList.forEach((item:any) => {
            subTotal += Number(item.product.amount) * Number(item.product.product.price)
        })

        return subTotal;
    }

    const isRateSelected = () => {
        return rates[selectedRate].amount === "00.00"
    }

    return (
        <div className="container p-4">
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">Carrito</li>
                    <li className="breadcrumb-item active">Informacion</li>
                </ol>
            </nav>
            <Link className="flex-c-m stext-101 cl0 size-103 bg1 bor1 p-lr-15 trans-04 m-b-30" style={{ width: '15%' }} to="/carrito">Volver al carrito</Link>
            <div className="row">
                <div className="col-12 col-md-6 mb-4 mb-md-0">
                    <form onSubmit={postQuotation}>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">Nombre</label>
                                    <input onChange={e => setName(e.target.value)} className="form-control mt-1" type="text" required placeholder="Nombre"/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">Compañia (Opcional)</label>
                                    <input onChange={e => setCompany(e.target.value)} className="form-control mt-1" type="text" placeholder="Compañia"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="">Calle 1</label>
                                    <input onChange={e => setStreetOne(e.target.value)} className="form-control mt-1" type="text" required placeholder="Calle 1"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="">Calle 2</label>
                                    <input onChange={e => setStreetTwo(e.target.value)} className="form-control mt-1" type="text" placeholder="Calle 2"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">Ciudad</label>
                                    <input onChange={e => setCity(e.target.value)} className="form-control mt-1" type="text" required placeholder="Ciudad"/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">Código Postal</label>
                                    <input onChange={e => setZipCode(e.target.value)} className="form-control mt-1" type="text" required placeholder="Código Postal"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">Estado o Provincia</label>
                                    <input onChange={e => setState(e.target.value)} className="form-control mt-1" type="text" required placeholder="Estado o Provincia"/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">Pais</label>
                                    <input className="form-control mt-1" type="text" required placeholder="Pais"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">Telefono</label>
                                    <input onChange={e => setPhone(e.target.value)} className="form-control mt-1" type="text" required placeholder="Telefono"/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">Correo</label>
                                    <input onChange={e => setMail(e.target.value)} className="form-control mt-1" type="text" required placeholder="Correo"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="from-">
                                    Favor de utilizar los mismos datos en todo el proceso.
                                    <label htmlFor="">Acepto terminos y Condiciones
                                        <input required type="checkbox"/>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <input type="submit" className="flex-c-m stext-101 cl0 size-103 bg1 bor1 p-lr-15 trans-04 m-b-30 m-t-30" value="Calcular envio"/>
                    </form>
                    <div>
                        {spinner ?
                            <Spinner />
                        : null}
                        {rates.length === 1 ? null :
                            <div>
                                <h4>Selecciona tu metodo de envio.</h4>
                                <select className="form-control" onChange={(e) => setShipmentRate(e.target.value)}>
                                {   rates.map((rate:any,index) => {

                                        const { provider, amount } = rate
                                        const { servicelevel = { 'name': '' } } = rate 

                                        return (
                                            <option key={index} value={index}>
                                                {provider}: {servicelevel.name} - ${amount}
                                            </option>
                                        )
                                    })
                                }
                                </select>
                                
                            </div>
                        }
                    </div>
                </div>
                <div className="col-12 col-md-6 text-center">
                    <h3>Resumen del Pedido</h3>
                    <table className="table">
                        <thead>
                            <tr className="table_head">
                                <th className="column-1 rmx-center">Producto</th>
                                <th className="column-1 rmx-center">Precio</th>
                                <th className="column-1 rmx-center">Cantidad</th>
                                <th className="column-1 rmx-center">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cartState.itemList.map((item: any, index: any) => {
                                const { title, img, price } = item.product.product;
                                const amount = item.product.amount;

                                return (
                                    <tr className="table_row" key={index}>
                                        <td className="column-1 rmx-center">
                                            <img src={img} alt="" height="50" width="50" /><br></br>
                                            <p style={{fontSize: '14px'}}>{title}</p>
                                        </td>
                                        <td className="column-1 rmx-center">$ {price}</td>
                                        <td className="column-1 rmx-center">
                                            <p>{amount}</p>
                                        </td>
                                        <td className="column-1 rmx-center">{`$ ${amount * Number(price)}`}</td>
                                    </tr>
                                )
                            })}
                            {selectedRate === 100 ? null : 
                                <tr>
                                    <td className="column-1">Envio: {rates[selectedRate].servicelevel.name}</td>
                                    <td className="column-1">--</td>
                                    <td className="column-1">--</td>
                                    <td className="column-1">$ {rates[selectedRate].amount}</td>
                                </tr>
                            }
                            <tr>
                                <td className="column-1 text-bold">Total: </td>
                                <td className="column-1">--</td>
                                <td className="column-1">--</td>
                                <td className="column-1">$ {getTotal()} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row justify-content-end">
                {isRateSelected() ? null :
                    <Link to={{ 
                        pathname: "/checkout/payment",
                        state: {
                            address_to: address_to,
                            selectedRate: rates[selectedRate]
                        }
                    }}>
                        <button className="flex-c-m stext-101 cl0 size-116 bg3 bor14 hov-btn3 p-lr-15 trans-04 pointer">
                            Proceder
                        </button>
                    </Link>
                }
            </div>
        </div>
    )
};

export default CheckoutAddress;