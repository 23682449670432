import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchApi } from '../../fetchApi/fetchApi';
import { addToCart } from '../../redux/actions/cartActions';

const ProductDetail = (props: any) => {

    const [product,setProduct] = useState({'id':'','title':'','description':'','img':'','price':'','stock':0})
    const [count, setCount] = useState(1);

    const dispatch = useDispatch()
    const _addToCart = (item: any) => { dispatch(addToCart(item)) }

    const addItemToCart = (item: object) => {
        _addToCart(item);
    }

    useEffect(() => {
        fetchApi('productos/id/' + props.productId, 'GET')
            .then(response => {
                setProduct(response[0])
            })
    }, [props.productId]);

    return (
        <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
                <div className="row">
                    <div className="col-md-6 col-lg-7 p-b-30">
                        <div className="pos-relative wrap-pic-w" style={{ maxWidth: '100%', maxHeight: '100%', paddingLeft: '50px' }}>
                            <img src={product.img} alt="IMG-PRODUCT" />
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-5 p-b-30">
                        <div className="p-r-50 p-t-5 p-lr-0-lg">
                            <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                                {product.title}
                            </h4>

                            <span className="mtext-106 cl2">
                                $ {product.price}
                            </span>

                            <p className="stext-102 cl3 p-t-23">
                                {product.description}
                            </p>

                            <div className="p-t-33">
                            </div>

                            <div className="flex-w flex-r-m p-b-10">
                                <div className="size-204 flex-w flex-m respon6-next">
                                    <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                                        <button disabled={count <= 1} onClick={() => setCount(count - 1)} className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                                            <i className="fs-16 zmdi zmdi-minus"></i>
                                        </button>

                                        <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" value={count} readOnly/>

                                        <div onClick={() => setCount(count + 1)} className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                                            <i className="fs-16 zmdi zmdi-plus"></i>
                                        </div>
                                    </div>
                                    {count <= product.stock && 
                                        <button onClick={() => addItemToCart({ product:product, amount: count })}  className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                                            Agregar al Carrito
                                        </button>}
                                    {count > product.stock && 
                                    <span className="flex-c-m stext-101 cl0  size-101  bor1 hov-btn1 p-lr-15 badge badge-danger">Agotado</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default ProductDetail;