import React from "react";
import Duyas from '../assets/img/Duyas.jpg';
import Bolsas from '../assets/img/Bolsas.jpg';
import Duyas_5 from '../assets/img/Duyas_5.jpg';

import '../assets/css/info.css';

const MateriasPrimas = (props: any) => {

    return (
        <div className="container m-bt-60">
            <div className="row p-b-148">
                <div className="col-md-7 col-lg-8">
                    <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                        <h5 className="mtext-111 cl2">
                        Decoracion
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                        Manejamos varios artículos de primera para la decoración en repostería. Junto con un gran repertorio de duyas. Lo invitamos a hojear el catálogo y descubra de primera mano nuestra amplia oferta de accesorios. <br /> 
                        -Duyas <br /> 
                        -Mangas <br /> 
                        -Aerógrafos <br /> 
                        -Bases Giratorias <br /> 
                        -Raspas -Kits de Decoración <br /> 
                        y más.
                        </p>


                    </div>
                </div>

                <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
                    <div className="how-bor1 ">
                        <div className="hov-img0">
                            <img src={Duyas} alt="IMG" />
                            <img src={Bolsas} alt="IMG" />
                            <img src={Duyas_5} alt="IMG" />
                        </div>
                    </div>
                </div> 
            </div>

        </div>
    )
}

export default MateriasPrimas;
