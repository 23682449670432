import React from "react";
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/icons/logo.png';
import { useLocation } from "react-router-dom";
import Cart from '../../pages/Cart/Cart';
import '../../assets/css/header.css';
import { showCart } from '../../redux/actions/cartActions';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApi } from "../../fetchApi/fetchApi";

const Header = () => {

    const cart = useSelector((state: any) => {
        return state.cart;
    });

    const location = useLocation();

    const dispatch = useDispatch()
    const _showCart = () => { dispatch(showCart()) }

    const activatCart = (e: any) => {
        e.preventDefault();
        _showCart()
    };

    const items = cart.itemList.length;
    const iconNoti = items !== 0 ? 'icon-header-noti' : '';

    const [whatsapp, setWhatsapp] = React.useState([]);
    React.useEffect(() => {
        fetchApi('whatsapp', 'GET')
            .then(response => {
                setWhatsapp(response[0].value);
            });
    }, [])

    return (
        <div>
            <div className="top-bar">
                <div className="content-topbar flex-sb-m h-full container">
                    <div className="left-top-bar"></div>
                    <div className="right-top-bar flex-w h-full">
                        <a href="https://www.facebook.com/rafmexoficial/" rel="noopener noreferrer" target="_blank" className="flex-c-m trans-04 p-lr-25">
                            <i className="fa fa-facebook"></i>
                        </a>
                        <a href="https://www.instagram.com/rafmexoficial/" rel="noopener noreferrer" target="_blank" className="flex-c-m trans-04 p-lr-25">
                            <i className="fa fa-instagram"></i>
                        </a>
                        <a href={'https://api.whatsapp.com/send?phone=' + whatsapp} rel="noopener noreferrer" target="_blank" className="flex-c-m trans-04 p-lr-25">
                            <i className="fa fa-whatsapp"></i>
                        </a>
                    </div>
                </div>
            </div>
            <Navbar bg="light" expand="lg">
                <div className="container">
                    <Navbar.Brand>
                        <Link to="/">
                            <img src={Logo} alt="IMG-LOGO" style={{'height':'100px'}}/>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link as={Link} className={location.pathname === '/' ? 'active-menu' : 'no-active'} to="/">Inicio</Nav.Link>
                            <Nav.Link as={Link} className={location.pathname === '/nosotros' ? 'active-menu' : 'no-active'} to="/nosotros">Nosotros</Nav.Link>
                            <Nav.Link as={Link} className={location.pathname === '/productos' ? 'active-menu' : 'no-active'} to="/productos">Productos</Nav.Link>
                            <Nav.Link as={Link} className={location.pathname === '/distribuidores' ? 'active-menu' : 'no-active'} to="/distribuidores">Distribuidores</Nav.Link>
                            <Nav.Link as={Link} className={location.pathname === '/novedades' ? 'active-menu' : 'no-active'} to="/novedades">Novedades</Nav.Link>
                            <Nav.Link as={Link} className={location.pathname === '/contacto' ? 'active-menu' : 'no-active'} to="/contacto">Contacto</Nav.Link>
                            <Nav.Link as={Link} className={location.pathname === '/tienda' ? 'active-menu' : 'no-active'} to="/tienda">Tienda</Nav.Link>
                        </Nav>
                        {location.pathname !== '/carrito' && location.pathname !== '/checkout' &&
                            <div className="wrap-icon-header flex-w flex-r-m">
                                <div onClick={(e) => activatCart(e)} style={{ fontSize: '30px' }} className={`icon-header-item cl2 hov-cl1 trans-04 p-r-11 p-l-10 js-show-cart ${iconNoti}`} data-notify={items}>
                                    <i className="zmdi zmdi-shopping-cart"></i>
                                </div>
                            </div>
                        }
                    </Navbar.Collapse>
                </div>
            </Navbar>
            <Cart/>
			<nav className="nav nav-pills nav-fill container">
				<a className="nav-item nav-link" href="/tienda/saborizantes">Saborizantes</a>
                <a className="nav-item nav-link" href="/tienda/colorantes">Colorantes</a>
                <a className="nav-item nav-link" href="/tienda/decoracion">Decoracion</a>
                <a className="nav-item nav-link" href="/tienda/materiasprimas">Materias Primas</a>
                <a className="nav-item nav-link" href="/tienda/fondant">Fondant</a>
                <a className="nav-item nav-link" href="/tienda/charolas">Charolas</a>
                <a className="nav-item nav-link" href="/tienda/moldes">Moldes</a>
                <a className="nav-item nav-link" href="/tienda/utensilios">Utensilios</a>
                <a className="nav-item nav-link" href="/tienda/">Todos</a>
			</nav>
        </div>
    )
}

export default Header;
