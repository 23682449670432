import React, { useState } from 'react';
import Bg03 from '../assets/img/bg-03.jpg';
import '../assets/css/distribuidores.css';
import Slider from "react-slick";
import SupplierModal from '../components/SupplierModal';
import data from './DummyDataDistribuidores';

const Distribuidores = () => {

    const [show, setShow] = useState(false);
    const [title, setTitle] = useState('');
    const handleClose = () => setShow(false);

    const showModalWithInfo = (name:any,info:any) => {
        setTitle(name);
        setShow(true);
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    return (
        <div className="">
            <section className="bg-img1 txt-center p-lr-15 p-tb-92" style={{ 'backgroundImage': `url(${Bg03})` }}>
                <h2 className="ltext-105 cl0 txt-center m-b-0">Distribuidores</h2>
            </section>
            <section className="bg0 back_map">
                <div className="container p-t-40 p-b-60">
                    <Slider {...settings}>
                        {data.map((item,index) => {
                            return (                 
                                <div key={index} className="item-slick2 p-l-15 p-r-15 p-t-15 p-b-15">
                                    <div className="slider-card-wrapper">
                                        <div className="img-hover-zoom" style={{ maxWidth: '100%' }}>
                                            <img src={item.img} alt="IMG-PRODUCT" />
                                            <button onClick={() => showModalWithInfo(item.name,item.locations)} className="btn-card flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                                                Ver
                                            </button>
                                        </div>
                                        <div className="block2-txt flex-w flex-t p-t-14">
                                            <div className="block2-txt-child1 flex-col-l ">
                                                <span className="stext-105 cl3 text_dist">
                                                    {item.name}
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </section>

            <SupplierModal title={title} show={show} handleClose={handleClose}></SupplierModal>

        </div>
    )
};
export default Distribuidores;