const initialState = {
    itemList: [],
    showCart: false
};

export default function (state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case 'ADD_TO_CART':
            var addItem = true;
            state.itemList.forEach(item => {
                if (item.product.product.id === action.payload.product.product.id) {
                    addItem = false;
                    item.product.amount += action.payload.product.amount;
                }
            });
            if (addItem) {
                state.itemList.push(action.payload);
            }
            
            return {
                ...state,
                showCart:true,
            };
        case 'SHOW_CART':
            return {
                ...state,
                showCart:true,
            };
        case 'HIDE_CART':
            return {
                ...state,
                showCart:false,
            };
        case 'EMPTY_CART':
            return {
                itemList: [],
                showCart:false,
            };
        case 'REMOVE_FROM_CART':
            state.itemList.forEach((item,index) => {
                if (item.product.product.id === action.payload.product.id) {
                    state.itemList.splice(index,1)
                }
            });
            return {
                ...state,
                showCart:false,
            };
        case 'INCREASE_QUANTITY':
            state.itemList.forEach(item => {
                if (item.product.product.id === action.payload.product.id) {
                    item.product.amount += 1;
                }
            });
            return {
                ...state,
                showCart:false,
            };
        case 'DECREASE_QUANTITY':
            state.itemList.forEach(item => {
                if (item.product.product.id === action.payload.product.id) {
                    item.product.amount -= 1;
                }
            });
            return {
                ...state,
                showCart:false,
            };
        
        default:
            return state;
    }
};