import React from 'react';
import { Link } from 'react-router-dom';

const Producto = (props: any) => {

    const { title, items, search } = props

    const getProducts = () => {

    }
    
    return (
        <div className="container">
            <h4 className="mtext-113 cl2 p-b-30">
                {title}
            </h4>
            <div className="row isotope-grid">
                {items.map((item:any,index:any) => {

                    if (String(item.title).toLowerCase().includes(search)) {
                        return (
                            <div key={index} className="col-sm-4 col-md-3 col-lg-2 p-b-35 isotope-item women" style={{ cursor: 'pointer' }}>
                                <div className="block2">
                                    <div className="block2-pic hov-img0">
                                        <img src={item.img} alt="IMG-PRODUCT" />
                                        <Link to={'/product/' + item.id}>
                                            <div className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                                                Ver
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="block2-txt flex-w flex-t p-t-14">
                                        <div className="block2-txt-child1 flex-col-l ">
                                            <div className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                                                {item.title}
                                            </div>
                                            <span className="stext-105 cl3">
                                                $ {item.price}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                })}
            </div>
        </div>
    )
}

export default Producto;