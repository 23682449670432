import React from "react";
import moldes from '../assets/img/Molde_Pan_Caja.jpg';
import placa3 from '../assets/img/Placa-3.jpg';
import placa1 from '../assets/img/Placa-1.jpg';
import '../assets/css/info.css';

const MateriasPrimas = (props: any) => {

    return (
        <div className="container m-bt-60">
            <div className="row p-b-148">
                <div className="col-md-7 col-lg-8">
                    <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                        <h5 className="mtext-111 cl2">
                        Moldes Nacionales
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                        Manejamos una muy amplia variedad de Moldes Nacionales de la mejor calidad. De diversos tamaños, materiales y usos, lo invitamos a buscar en nuestro catálogo los moldes nacionales de su preferencia.
                        </p>
                        <h5 className="mtext-111 cl2">
                    Moldes Importados.
                    </h5>
                    <p className="stext-113 cl6 m-b-0 text-black">
                    Somos distribuidores oficiales de la marca estadounidense Chicago Metallic en México. Los cuáles se reconocen por su alta calidad y duración. Productos ampliamente recomendados para el usuario industrial. Conoce nuestros moldes importados en nuestro catálogo.
                    </p>

                    </div>
                </div>

                
            <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
                    <div className="how-bor1 ">
                        <div className="hov-img0">
                            <img src={moldes} alt="IMG" />
                            <img src={placa3} alt="IMG" />
                            <img src={placa1} alt="IMG" />
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default MateriasPrimas;
