/* eslint-disable global-require */
/* eslint-disable import/no-extraneous-dependencies */
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';

function saveToLocalStorage(state) {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem('state', serializedState);
	} catch (e) {
		console.log(e);
	}
}

function loadFromLocalStorage() { 
	try {
		const serializedState = localStorage.getItem('state');
		if (serializedState === null) return undefined;
		return JSON.parse(serializedState);
	} catch(e) {
		console.log(e);
		return undefined;
	}
}

export default function configureStore() {
	const middlewares = [];

	if (process.env.NODE_ENV === 'development') {
		const { logger } = require(`redux-logger`);
		middlewares.push(logger);
	}

	const middlewareEnhacer = applyMiddleware(thunk, ...middlewares);
	const composeEnhancers = composeWithDevTools(middlewareEnhacer);

	const persistedState = loadFromLocalStorage();

	const store = createStore(
		rootReducer,
		persistedState,
		composeEnhancers
	);

	store.subscribe( () => saveToLocalStorage(store.getState()));

	if (module.hot) {
		module.hot.accept('./reducers', () => {
			const nextReducer = require('./reducers/index').default;
			store.replaceReducer(nextReducer);
		});
	}

	return store;
}
