import React from 'react';

const NotFound: React.FC = () => {
  return (
    <div className="not-found-box text-center">
        <h1>404</h1>
        <h2>Page not Found</h2>
        <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
    </div>
  );
};

export default NotFound;

