import React from "react";
import prod01 from '../assets/img/prod-01.jpg';
import prod02 from '../assets/img/prod-02.jpg';
import prod05 from '../assets/img/prod-05.jpg';
import prod10 from '../assets/img/prod-10.jpg';

const Colorantes = (props: any) => {

    return (
        <section className="bg0 m-t-40 p-b-120">
            <div className="container">
                <div className="row p-b-148">
                    <div className="col-md-7 col-lg-8">
                        <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                            <h3 className="mtext-111 cl2 p-b-16">
                                Saborizantes líquidos RAFMEX
							</h3>
                            <p className="stext-113 cl6 p-b-26">
                                Saborizantes puros y altamente concentrados para la industria panificadora, pastelera, galletera y similares.
                            </p>
                            <p className="stext-113 cl6 p-b-26 mb-0">
                                Saborizantes combinados RAFMEX (SCR), son una combinación de sabor MANTEQUILLA con sabor y aroma de:
                            </p>
                            <p className="stext-113 cl6 p-b-26" style={{ 'color':'#004593' }}>
                                - Naranja - Limón - Almendra - Coco - Plátano - Vainilla - Canela
                            </p>
                            <p className="stext-113 cl6 p-b-26 mb-0">
                                Saborizantes puros RAFMEX (SPR), altamente concentrados, proporcionan el sabor y aroma especifico.
                            </p>
                            <p className="stext-113 cl6 p-b-26 mb-0" style={{ 'color':'#004593' }}>
                                - Mantequilla Líquida - Chocolate - Nuez - Vainilla
                                - Vainilla 16 X (concentrado) - Nata
                            </p>
                        </div>
                    </div>

                    <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
                        <div className="how-bor1 ">
                            <div className="hov-img0">
                                <img src={prod01} alt="IMG" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="order-md-2 col-md-7 col-lg-8 p-b-30">
                        <div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md">
                            <h3 className="mtext-111 cl2 p-b-16">
                                Sabor en pasta RAFTEQUILLA
							</h3>
                            <div className="stext-113 cl6 p-b-26 about_text">
                                Concentrado de mantequilla en pasta para reforzar el sabor a la mantequilla natural al usar mantecas o margarinas de origen vegetal.
                            </div>
                            <h4>
                                Modo de Uso
                            </h4>
                            <p className="stext-114 cl6 p-r-40 p-b-11">
                                Se recomienda aplicar 15 a 30g por kg de manteca o margarina.
                            </p>
                            <p className="stext-113 cl6 p-b-26" style={{ 'color': '#004593' }}>
                                Presentación: Envases de 17 kg y 2 kg.
                            </p>
                        </div>
                    </div>
                    <div className="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
                        <div className="how-bor2">
                            <div className="hov-img0">
                                <img src={prod02} alt="IMG" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-b-148">
                    <div className="col-md-7 col-lg-8">
                        <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                            <h3 className="mtext-111 cl2 p-b-16">
                                Sabor Color Aroma
							</h3>
                            <p className="stext-113 cl6 p-b-26">
                                Sabor concentrado líquido, que proporciona color y aroma.
                            </p>
                            <p className="stext-113 cl6 p-b-26" style={{ 'color': '#004593' }}>
                                - Mora Azul - Uva - Grosella - Fresa - Mango - Piña - Cereza - Durazno - Frutos Rojos - Rompope
                            </p>
                            <p className="stext-113 cl6 p-b-26 mb-0">
                                Modo de uso
                            </p>
                            <p className="stext-113 cl6 p-b-26">
                                En la repostería fría para gelatinas, natillas, mousses, cheesecake frío. De igual manera se emplea para aguas frescas, nieves, helados, raspados, smoothies, frappes, coulis, gomitas, entre otros.
                            </p>
                            <p className="stext-113 cl6 p-b-26 mb-0" style={{ 'color': '#004593' }}>
                                Presentación: Envases de 1 l y 125 ml.
                            </p>
                        </div>
                    </div>

                    <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
                        <div className="how-bor1 ">
                            <div className="hov-img0">
                                <img src={prod05} alt="IMG" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="order-md-2 col-md-7 col-lg-8 p-b-30">
                        <div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md">
                            <h3 className="mtext-111 cl2 p-b-16">
                                Sabor Aroma
							</h3>
                            <div className="stext-113 cl6 p-b-26 about_text">
                                Sabor liquido translucido que otroga sabor y aroma.
                            </div>
                            <p className="stext-114 cl6 p-r-40 p-b-11">
                                Modo de Uso
                            </p>
                            <span className="stext-111 cl8">
                                En la repostería fría y productos terminados que no se requiera adicionar color como gelatina.
                                Presentación: Envases de 1l y 125ml.
                            </span>
                        </div>
                    </div>
                    <div className="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
                        <div className="how-bor2">
                            <div className="hov-img0">
                                <img src={prod10} alt="IMG" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Colorantes;
