import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { increaseItemQuantity ,decreaseItemQuantity, deleteItem } from '../redux/actions/cartActions';
import { DeleteFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { hideCart } from '../redux/actions/cartActions';

const MainCart = () => {
  
    const cart = useSelector((state: any) => {
        return state.cart;
    });

    const dispatch = useDispatch()
    const _hideCart = () => {hideCart()}
    const _increaseItemQuantity = (product: any) => { dispatch(increaseItemQuantity(product)) }
    const _decreaseItemQuantity = (product: any) => { dispatch(decreaseItemQuantity(product)) }
    const _deleteItem = (product: any) => { dispatch(deleteItem(product)) }

    useEffect(() => {
        _hideCart();
    },[]);

    const totalAmount = () => {
        var total = 0;
        cart.itemList.forEach((item: any) => {
            total += (parseFloat(item.product.product.price) * parseInt(item.product.amount))
        })
        return total;
    }
    let proceder_pago = false;

    const increaseQuantity = (product:any) => {
        _increaseItemQuantity(product)
    }

    const decreaseQuantity = (product:any) => {
        _decreaseItemQuantity(product)
    }

    const deleteItemFromCart = (product:any) => {
        _deleteItem(product)
    }
    return (
        <div>
            {cart.itemList.length !== 0 ?
                <div className="bg0 p-t-75 p-b-85">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 m-lr-auto m-b-50">
                                <div className="m-l-25 m-r--38 m-lr-0-xl">
                                    <div className="wrap-table-shopping-cart">
                                        <table className="table-shopping-cart">
                                            <thead>
                                                <tr className="table_head">
                                                    <th className="column-1 rmx-center">Producto</th>
                                                    <th className="column-1 rmx-center">Cantidad</th>
                                                    <th className="column-1 rmx-center">Precio</th>
                                                    <th className="column-1 rmx-center">Total</th>
                                                    <th className="column-1 rmx-center">Borrar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cart.itemList.map((item: any, index:any) => {
                                                    const { title, img, price, stock } = item.product.product;
                                                    const amount = item.product.amount;
                                                    if(amount > stock){
                                                        proceder_pago = true;
                                                    }
                                                    return (
                                                        <tr className="table_row" key={index}>
                                                            <td className="column-1 rmx-center">
                                                                {title}
                                                                <img src={img} alt=""/>
                                                            </td>
                                                            <td className="column-1 rmx-center">
                                                                <div className="wrap-num-product flex-w m-l-auto m-r-0">
                                                                    <button disabled={amount<=1} onClick={(e) => decreaseQuantity(item.product.product) } className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                                                                        <i className="fs-16 zmdi zmdi-minus"></i>
                                                                    </button>
                                                                    <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" value={amount} readOnly/>
                                                                    {amount < stock && <div onClick={(e) => increaseQuantity(item.product.product)} className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                                                                        <i className="fs-16 zmdi zmdi-plus"></i>
                                                                    </div>} 
                                                                </div>
                                                            </td>
                                                            <td className="column-1 rmx-center">$ {price}</td>
                                                            <td className="column-1 rmx-center">{`$ ${amount * Number(price)}`}</td>
                                                            <td className="column-1 rmx-center">
                                                                <div className="rmx-cursor-hand">
                                                                    <Tooltip placement="top" title="Quitar del carrito">
                                                                        <DeleteFilled onClick={(e) => deleteItemFromCart(item.product.product)} style={{ color: '#d20202', fontSize: 'xx-large' }} />
                                                                    </Tooltip>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {cart.itemList.length !== 0 && (
                            <div className="row">
                                <div className="col-lg-10 m-lr-auto m-b-50">
                                    <div className="bor10 p-lr-40 p-t-30 p-b-40 m-l-63 m-r-40 m-lr-0-xl p-lr-15-sm">
                                        <h4 className="mtext-109 cl2 p-b-30">
                                            Pago
                                        </h4>
                                        <div className="flex-w flex-t bor12 p-b-13">
                                            <div className="size-208">
                                                <span className="stext-110 cl2">
                                                    Subtotal:
                                                </span>
                                            </div>

                                            <div className="size-209">
                                                <span className="mtext-110 cl2">
                                                    $ {totalAmount()}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4 offset-8">
                                            <Link to="/checkout/address">
                                                <button disabled = {proceder_pago} className="flex-c-m stext-101 cl0 size-116 bg3 bor14 hov-btn3 p-lr-15 trans-04 pointer">
                                                    Proceder
                                                </button>
                                            </Link>
                                            {proceder_pago && <h4 className="mtext-109 cl2 p-b-30">
                                            *Alguno de los productos que agregaste excede las existencias
                                        </h4>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div>
                </div>
            :
                <section className="bg-img1 txt-center p-lr-15 p-tb-92" style={{ backgroundColor: 'white', padding: '300px 0 300px 0' }}>
                    <h2 className="ltext-105 txt-center">
                        Tu carrito de compras esta vacío
                    </h2>
                </section>
            }
            
        </div>
    )
};


export default MainCart;