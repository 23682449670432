import React from "react";
import prod07 from '../assets/img/prod-07.jpg';
import crema_pastelera from '../assets/img/crema_pastelera.jpg';
import harina_de_centeno_preparada from '../assets/img/harina_de_centeno_preparada.jpg';
import lecitina_de_soya from '../assets/img/lecitina_de_soya.jpg';
import gluten_de_trigo from '../assets/img/gluten_de_trigo.jpg';
import alcaravea from '../assets/img/alcaravea.jpg';
import azucar_glass from '../assets/img/azucar_glass.jpg';
import sustituto_de_canela from '../assets/img/sustituto_de_canela.jpg';
import sustituto_de_cocoa from '../assets/img/sustituto_de_cocoa.jpg';

import '../assets/css/info.css';

const MateriasPrimas = (props: any) => {

    return (
        <div className="container m-bt-60">
            <div className="row p-b-148">
                <div className="col-md-7 col-lg-8">
                    <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                        <h5 className="mtext-111 cl2">
                            Royal Icing
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                            Preparación en polvo que, al mezclarse con agua, forma una pasta de consistencia variable dependiendo de la cantidad de agua empleada. Se utiliza como cobertura o para decoración de galletas, paletas de bombón , macarons, pop cakes, pasteles, calaveritas de azúcar, pastillaje, etc.
                        </p>
                        <p className="stext-113 cl6 text-blue">
                            Presentación: Bolsa 5 kg y 500 g
                        </p>

                    </div>
                </div>

                <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
                    <div className="how-bor1 ">
                        <div className="hov-img0">
                            <img src={prod07} alt="IMG" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row p-b-148">
                <div className="order-md-2 col-md-7 col-lg-8 p-b-30">
                    <div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md ">
                    <h5 className="mtext-111 cl2">
                    Crema Pastelera
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                        De práctica y rápida preparación, solo mezclar con leche o agua (al tiempo) y está lista para usarse. No requiere cocción ni otro insumo. La crema pastelera RAFMEX tiene un exquisito sabor con toque a vainilla y una consistencia cremosa ideal para hornear o usar en frío. Para panes cubiertos, rellenos y postres como natillas, budines, etc.
                        </p>
                        <p className="stext-113 cl6 text-blue">
                        Presentación: Envases de 25 kg, 5 kg, 1 kg y 500 g.
                        </p>
                    </div>
                </div>
                <div className="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
                    <div className="how-bor2">
                        <div className="hov-img0">
                            <img src={crema_pastelera} alt="IMG" />
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row p-b-148">
                <div className="col-md-7 col-lg-8">
                    <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                        <h5 className="mtext-111 cl2">
                            Harina de Centeno Preparada
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                        Para la elaboración de panes tipo europeo.
                            Esta mezcla, formulada en México con ingredientes de importación, permite, mediante los métodos tradicionales de elaboración de pan blanco, obtener pan de centeno de excelente calidad, con ventajas económicas. Desarrolla gran sabor y aroma típico de un pan de centeno alemán.
                        </p>
                        <p className="stext-113 cl6 text-blue">
                        Presentación: Envases de 60 ml y 250 ml.
                        </p>

                    </div>
                </div>

                <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
                    <div className="how-bor1 ">
                        <div className="hov-img0">
                            <img src={harina_de_centeno_preparada} alt="IMG" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row p-b-148">
                <div className="order-md-2 col-md-7 col-lg-8 p-b-30">
                    <div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md ">
                    <h5 className="mtext-111 cl2">
                            Leticina de Soya
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                            Para la elaboración de panes tipo europeo.
                            Emulsificante y antioxidante. En el pan y bizcocho mejora la dispersión de la mantequilla o grasa, reduciendo el tiempo de batido. El pan terminado tiene una textura más fina, uniforme, con mayor resistencia al resecamiento; aumenta la vida en anaquel.
                        </p>
                        <p className="stext-113 cl6 text-blue">
                            Presentación: Envases de 200 kg, 20 kg y 4 kg.
                        </p>
                    </div>
                </div>
                <div className="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
                    <div className="how-bor2">
                        <div className="hov-img0">
                            <img src={lecitina_de_soya} alt="IMG" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row p-b-148">
                <div className="col-md-7 col-lg-8">
                    <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                    <h5 className="mtext-111 cl2">
                            Alcaravea (condimento)
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                            Comino tipo Holandés que se usa para acondicionar y/o decorar los panes de centeno y panes negros, dándole un sabor característico a pan europeo.
                        </p>
                        <p className="stext-113 cl6 text-blue">
                            Presentación: Envases de 5 kg.
                        </p>

                    </div>
                </div>

                <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
                    <div className="how-bor1 ">
                        <div className="hov-img0">
                            <img src={alcaravea} alt="IMG" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row p-b-148">
                <div className="order-md-2 col-md-7 col-lg-8 p-b-30">
                    <div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md ">
                    <h5 className="mtext-111 cl2">
                            Azúcar Glass
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                            Azúcar refinada pulverizada De color blanco que se emplea para endulzar y decorar productos de panificación, de repostería y para la elaboración de merengue. Este producto cuenta con un aditivo antiapelmazante, el cual evita que se endurezca, conservando sus características originales hasta en ambiente húmedo por un prolongado período de tiempo
                        </p>
                        <p className="stext-113 cl6 text-blue">
                            Presentación: Envases de 25 kg y 5 kg.
                        </p>
                    </div>
                </div>
                <div className="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
                    <div className="how-bor2">
                        <div className="hov-img0">
                            <img src={azucar_glass} alt="IMG" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row p-b-148">
                <div className="col-md-7 col-lg-8">
                    <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                    <h5 className="mtext-111 cl2">
                            Sustituto de Canela
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                            Se utiliza para darle sabor característico a cierto tipo de panes (roles de canela o pan fino). Su consistencia hace que su aplicación sea más práctica, ya sea en el batido o en la decoración
                        </p>
                        <p className="stext-113 cl6 text-blue">
                            Presentación: Envases de 25 kg y 5 kg.
                        </p>

                    </div>
                </div>

                <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
                    <div className="how-bor1 ">
                        <div className="hov-img0">
                            <img src={sustituto_de_canela} alt="IMG" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row p-b-148">
                <div className="order-md-2 col-md-7 col-lg-8 p-b-30">
                    <div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md ">
                    <h5 className="mtext-111 cl2">
                            Sustituto de cocoa en Polvo
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                            Se utiliza para darle sabor y color de chocolate a las conchas de chocolate, panes o mamones, planchas, puerquitos, piedras, etc.
                        </p>
                        <p className="stext-113 cl6 text-blue">
                            Presentación: Envases de 25 kg y 5 kg.
                        </p>
                    </div>
                </div>
                <div className="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
                    <div className="how-bor2">
                        <div className="hov-img0">
                            <img src={sustituto_de_cocoa} alt="IMG" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row p-b-148">
                <div className="col-md-7 col-lg-8">
                    <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                        <h5 className="mtext-111 cl2">
                            Gluten de Trigo
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                            Proteína natural de trigo que se agrega para obtener una mejor textura y desarrollo del pan. Es un mejorador natural de harina. Da ventajas al obtener un aumento del volumen del pan; aumenta la tolerancia de fermentación, en la producción de panes tipo francés o vienés (pan de caja, hamburguesas y hot dog) los cuales retienen mucho gas. Retiene agua el doble de su peso, por lo cual aumenta el rendimiento y la vida en anaquel.
                        </p>
                        <p className="stext-113 cl6 text-blue">
                            Presentación: Envases de 25 kg y 10 kg.
                        </p>
                    </div>
                </div>

                <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
                    <div className="how-bor1 ">
                        <div className="hov-img0">
                            <img src={gluten_de_trigo} alt="IMG" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MateriasPrimas;
