import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideCart } from '../../redux/actions/cartActions';
import { useHistory } from 'react-router-dom';

const Cart = () => {

    const cartState = useSelector((state: any) => {
        return state.cart;
    });

    const history = useHistory();

    const dispatch = useDispatch()
    const _hideCart = () => { dispatch(hideCart()) }

    const closeCart = () => {
        _hideCart();
    }

    const totalAmount = () => {
        var total = 0;
        cartState.itemList.forEach((element:any) => {
            total += (parseFloat(element.product.product.price) * parseInt(element.product.amount))
        });
        return total;
    }

    const goToMainCart = () => {
        _hideCart();
        history.push('/carrito');
    }

    return (
        <div className={`wrap-header-cart js-panel-cart ${cartState.showCart ? 'show-header-cart' : ''}`}>
            <div className="s-full js-hide-cart"></div>
            <div className="header-cart flex-col-l p-l-65 p-r-25">
                <div className="header-cart-title flex-w flex-sb-m p-b-8">
                    <span className="mtext-103 cl2">
                        Tu carrito
                    </span>

                    <div onClick={closeCart} className="fs-35 lh-10 cl2 p-lr-5 pointer hov-cl1 trans-04 js-hide-cart">
                        <i className="zmdi zmdi-close"></i>
                    </div>
                </div>
                {cartState.itemList.length !== 0 ?
                    <div className="header-cart-content flex-w js-pscroll">
                        <ul className="header-cart-wrapitem w-full">
                            {cartState.itemList.map((item:any, index:any) => {
                                return (
                                    <li className="header-cart-item flex-w flex-t m-b-12" key={index}>
                                        <div className="header-cart-item-img">
                                            <img src={item.product.product.img} alt="IMG" />
                                        </div>

                                        <div className="header-cart-item-txt p-t-8">
                                            <button className="header-cart-item-name m-b-18 hov-cl1 trans-04">
                                                {item.product.product.title}
                                            </button>

                                            <span className="header-cart-item-info">
                                                {item.product.amount} x {item.product.product.title}
                                            </span>

                                            <span className="">
                                                $ {parseFloat(item.product.product.price) * parseInt(item.product.amount)}
                                            </span>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                        <div className="w-full">
                            <div className="header-cart-total w-full p-tb-40">
                                Total: $ {totalAmount()}
                            </div>

                            <div className="header-cart-buttons flex-w w-full">
                                <button className="flex-c-m stext-101 cl0 size-107 bg3 bor2 hov-btn3 p-lr-15 trans-04 m-r-8 m-b-10" onClick={goToMainCart}>
                                    Proceder a Pago
                                </button>
                            </div>
                        </div>

                    </div>
                : (
                    <p className="p-b-15">
                        Tu carrito de compras esta vacío
                    </p>
                )}
            </div>
        </div>
    )
};


export default Cart;