import React from 'react';
import { IonApp } from '@ionic/react';

/* Custom Project */
import { BrowserRouter as Router, Switch, Route, useParams, useRouteMatch } from "react-router-dom";

/* Header */
import Header from './components/Header/Header';
import FooterApp from './components/Footer/Footer';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Template Pongo CSS */
import './assets/plugins/font-awesome/css/font-awesome.min.css'
import './assets/plugins/bootstrap-daterangepicker/daterangepicker.css'
import './assets/plugins/animate/animate.css'
import './assets/css/main.css'
import './assets/css/pdv.css'

/* Material Design */
import './assets/fonts/iconic/css/material-design-iconic-font.min.css';

/* Template Pongo JS */
import 'bootstrap/dist/css/bootstrap.min.css';

/* Theme variables */
import './theme/variables.css';

import Home from './pages/Main';
import Nosotros from './pages/Nosotros';
import Productos from './pages/Productos';
import Distribuidores from './pages/Distribuidores';
import Novedades from './pages/Novedades';
import Contacto from './pages/Contacto';
import MainCart from './pages/MainCart';
import Saborizantes from './pages/Saborizantes';
import Colorantes from './pages/Colorantes';
import MateriasPrimas from './pages/MateriasPrimas';
import Foundant from './pages/Foundant';
import Moldes from './pages/Moldes';
import Charolas from './pages/Charolas';
import Utensilios from './pages/Utensilios';
import Decoracion from './pages/Decoracion';
import Catalogo from './pages/Catalogo';
import Checkout from './pages/Cart/Checkout';
import CheckoutAddress from './pages/Cart/CheckoutAddress';
import ProductDetail from './pages/Productos/ProductDetail';
import NotFound from './pages/404';

/* Import Local Styles */
import './assets/rafmexassets/App.css';
import './assets/rafmexassets/Main.css';
import './assets/rafmexassets/util.css';
import './assets/rafmexassets/css/extras.css';

/* antd */
import 'antd/dist/antd.css';

/* dotenv */
require('dotenv').config()

const Product = () => {
  let { productId } = useParams();
  return (
    <div>
      <ProductDetail productId={productId} ></ProductDetail>
    </div>
  );
}

const Products = () => {
  let { path } = useRouteMatch()

  return (
    <div>
      <Switch>
        <Route path={`${path}/:productId`}>
          <Product />
        </Route>
      </Switch>
    </div>
  )
}

const Main: React.FC = () => {
  return (
    <IonApp style={{ overflowY: 'scroll' }}>
      <Router basename={'/'}>
        <Header></Header>
        <Switch>
          <Route path="/" component={Home} exact={true} />
          <Route path="/nosotros" component={Nosotros} exact={true}/>
          <Route path="/productos" component={Productos} exact={true}/>
          <Route path="/distribuidores" component={Distribuidores} exact={true}/>
          <Route path="/novedades" component={Novedades} exact={true}/>
          <Route path="/saborizantes" component={Saborizantes} exact={true}/>
          <Route path="/colorantes" component={Colorantes} exact={true}/>
          <Route path="/materiasprimas" component={MateriasPrimas} exact={true}/>
          <Route path="/Fondant" component={Foundant} exact={true}/>
          <Route path="/Moldes" component={Moldes} exact={true}/>
          <Route path="/Decoracion" component={Decoracion} exact={true}/>
          <Route path="/Utensilios" component={Utensilios} exact={true}/>
          <Route path="/Charolas" component={Charolas} exact={true}/>
          <Route path="/tienda" component={Catalogo} exact={true}/>
          <Route path="/tienda/:buscar" component={Catalogo} exact={true}/>
          <Route path='/product'>
            <Products/>
          </Route>
          <Route path="/contacto" component={Contacto} exact={true}/>
          <Route path="/carrito" component={MainCart} exact={true}/>
          <Route path="/checkout/address" component={CheckoutAddress} exact={true}/>
          <Route path="/checkout/payment" component={Checkout} exact={true}/>
          <Route component={NotFound}/>
        </Switch>
        <FooterApp></FooterApp>
      </Router>
    </IonApp>
  )
}

export default Main;
