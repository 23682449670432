import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import configureStore from './redux/store';

const store = configureStore();

// const store = createStore(
//     rootReducer,
//     applyMiddleware(thunk)
//   );

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));