import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './styles.css';
import '../../assets/css/checkout.css';
import { fetchPreferenceId } from '../../fetchApi/fetchApi';
import 'react-credit-cards/es/styles-compiled.css';
import { Link, useHistory } from "react-router-dom";

const Checkout = (props:any) => {

  const cartState = useSelector((state: any) => {
    return state.cart;
  });

  const [addressTo, setAddressTo] = useState(
    {
      'name': '',
      'company': '',
      'street1': '',
      'street2': '',
      'city': '',
      'zip': '',
      'state': '',
      'country': '',
      'phone': '',
      'mail': '',
    }
  );
  const [selectedRate, setSelectedRate] = useState({
    "rate_id": "",
    "provider": "",
    "provider_image_75": "",
    "currency": "",
    "days": 0,
    "amount": "00.00",
    "account_id": "",
    "servicelevel": {
      "name": "",
      "token": ""
    },
    "status": "HOLD"
  })

  const history = useHistory();

  const getTotal = () => {

    var subTotal = Number(selectedRate.amount);

    cartState.itemList.forEach((item: any) => {
      subTotal += Number(item.product.amount) * Number(item.product.product.price)
    })

    return subTotal;
  }

  const getPreferenceId = () => {

    var body = {
      'items': new Array(0),
      'payer': {
        'name': addressTo.name,
        'email': addressTo.mail
      }
    }

    body.items.push({
      "title": "Envio",
      "quantity": 1,
      "currency_id": "MXN",
      "unit_price": Number(props.location.state.selectedRate.amount)
    })

    cartState.itemList.forEach((element: any) => {

      var item = {
        title: element.product.product.title,
        quantity: Number(element.product.amount),
        currency_id: 'MXN',
        unit_price: Number(element.product.product.price),
      }
      body.items.push(item)
    });

    var items = JSON.stringify(body);

    fetchPreferenceId(items)
    .then((response)=>{

      if(response!==undefined){

        const script = document.createElement("script");
        script.src = "https://www.mercadopago.com.mx/integrations/v1/web-payment-checkout.js"
        script.setAttribute("data-preference-id", response);
        script.async = true;
        document.getElementById('mercado-pago-form')?.appendChild(script);
      }

    })

    
  }

  useEffect(() => {

    if (props.location.state !== undefined) {
      setSelectedRate(props.location.state.selectedRate);
      setAddressTo(props.location.state.address_to);
      getPreferenceId();
    } else {
      history.push('/')
    }
    
  }, [])
  
  return (
    <div className="container p-4">
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">Carrito</li>
          <li className="breadcrumb-item">Informacion</li>
          <li className="breadcrumb-item active">Pago</li>
        </ol>
      </nav>
      <Link className="flex-c-m stext-101 cl0 size-103 bg1 bor1 p-lr-15 trans-04 m-b-30" style={{width:'15%'}} to="/checkout/address">Volver a datos</Link>
      <div className="row">
        <div className="col-12 col-md-6 mb-4 mb-md-0">
          <h3>Direccion</h3>
          <table className="table-sm">
            <tbody>
              <tr>
                <td className="column-1">Nombre</td>
                <td className="column-1">{addressTo.name}</td>
              </tr>
              {addressTo.company === '' ? null :
              <tr>
                <td className="column-1">Compañia</td>
                <td className="column-1">{addressTo.company}</td>
              </tr>
              }
              <tr>
                <td className="column-1">Calle 1</td>
                <td className="column-1">{addressTo.street1}</td>
              </tr>
              {addressTo.street2 === '' ? null :
              <tr>
                <td className="column-1">Calle 2</td>
                <td className="column-1">{addressTo.street2}</td>
              </tr>
              }
              <tr>
                <td className="column-1">Ciudad</td>
                <td className="column-1">{addressTo.city}</td>
              </tr>
              <tr>
                <td className="column-1">Codigo Postal</td>
                <td className="column-1">{addressTo.zip}</td>
              </tr>
              <tr>
                <td className="column-1">Estado</td>
                <td className="column-1">{addressTo.state}</td>
              </tr>
              <tr>
                <td className="column-1">Pais</td>
                <td className="column-1">{addressTo.country}</td>
              </tr>
              <tr>
                <td className="column-1">Telefono</td>
                <td className="column-1">{addressTo.phone}</td>
              </tr>
              {addressTo.mail === '' ? null :
              <tr>
                <td className="column-1">Mail</td>
                <td className="column-1">{addressTo.mail}</td>
              </tr>
              }
            </tbody>
          </table>
        </div>
        <div className="col-12 col-md-6 text-center">
          <h3>Resumen del Pedido</h3>
          <table className="table">
            <thead>
              <tr className="table_head">
                <th className="column-1 rmx-center">Producto</th>
                <th className="column-1 rmx-center">Cantidad</th>
                <th className="column-1 rmx-center">Precio</th>
                <th className="column-1 rmx-center">Total</th>
              </tr>
            </thead>
            <tbody>
              {cartState.itemList.map((item: any, index: any) => {
                const { title, img, price } = item.product.product;
                const amount = item.product.amount;

                return (
                  <tr className="table_row" key={index}>
                    <td className="column-1 rmx-center">
                      <img src={img} alt="" height="50" width="50" /><br></br>
                      {title}
                    </td>
                    <td className="column-1 rmx-center">
                      <p>{amount}</p>
                    </td>
                    <td className="column-1 rmx-center">$ {price}</td>
                    <td className="column-1 rmx-center">{`$ ${amount * Number(price)}`}</td>
                  </tr>
                )
              })}
              {selectedRate === undefined ? null :
                <tr>
                  <td className="column-1">Envio: {selectedRate.servicelevel.name}</td>
                  <td className="column-1">--</td>
                  <td className="column-1">--</td>
                  <td className="column-1">$ {selectedRate.amount}</td>
                </tr>
              }
              <tr>
                <td className="column-1 text-bold">Total: </td>
                <td className="column-1">--</td>
                <td className="column-1">--</td>
                <td className="column-1">$ {getTotal()} </td>
              </tr>
            </tbody>
          </table>
          <form id="mercado-pago-form"></form>
        </div>
      </div>
    </div>
  )
};

export default Checkout;