import React from "react";
import charolas from '../assets/img/Charolas.jpg'; 
import charola from '../assets/img/Charola.jpg'; 

import '../assets/css/info.css';

const MateriasPrimas = (props: any) => {

    return (
        <div className="container m-bt-60">
            <div className="row p-b-148">
                <div className="col-md-7 col-lg-8">
                    <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                        <h5 className="mtext-111 cl2">
                        Charolas Nacionales
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                        Manejamos una muy amplia variedad de Charolas Nacionales de la mejor calidad. De diversos tamaños, materiales y usos, lo invitamos a buscar en nuestro catálogo las charolas nacionales de su preferencia.
                        </p>


                    </div>
                </div>

                 <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
                    <div className="how-bor1 ">
                        <div className="hov-img0">
                            <img src={charolas} alt="IMG" />
                            <img src={charola} alt="IMG" />
                        </div>
                    </div>
                </div> 
            </div>

        </div>
    )
}

export default MateriasPrimas;
