import React from "react";
import Brocha from '../assets/img/Brocha.jpg';
import Exhibidor from '../assets/img/Exhibidor_Domo.jpg';
import Guantes from '../assets/img/Guantes.jpg';

import '../assets/css/info.css';

const MateriasPrimas = (props: any) => {

    return (
        <div className="container m-bt-60">
            <div className="row p-b-148">
                <div className="col-md-7 col-lg-8">
                    <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                        <h5 className="mtext-111 cl2">
                        Utensilios
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                        Amplio catálogo de utensilios y accesorios para el experto en panificación e industria alimenticia. <br /> 
                        -Cortadores <br /> 
                        -Rodillos <br /> 
                        -Cuchillos <br /> 
                        -Expátulas <br /> 
                        -Brochas <br /> 
                        -Termómetros <br /> 
                        -Guantes<br /> 
                        -Espigueros <br /> 
                        y muchos accesorios más.
                        </p>
                    </div>
                </div>

                <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
                    <div className="how-bor1 ">
                        <div className="hov-img0">
                            <img src={Brocha} alt="IMG" />
                            <img src={Exhibidor} alt="IMG" />
                            <img src={Guantes} alt="IMG" />
                        </div>
                    </div>
                </div> 
            </div>

        </div>
    )
}

export default MateriasPrimas;
