import React from "react";
import fondant from '../assets/img/Fondant.jpg'; 

import '../assets/css/info.css';

const MateriasPrimas = (props: any) => {

    return (
        <div className="container m-bt-60">
            <div className="row p-b-148">
                <div className="col-md-7 col-lg-8">
                    <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                        <h5 className="mtext-111 cl2">
                        Fondant Belanieve
                        </h5>
                        <p className="stext-113 cl6 m-b-0 text-black">
                        El fondant BELANIEVE es una pasta elástica de color blanco que sirve para recubrimiento de pasteles, galletas y otros productos. Además se utiliza para modelar figuras, flores, y decoraciones, es muy fácil de usar y tiene un delicioso sabor a vainilla. Su flexibilidad y elasticidad facilita la elaboración de capas finas y decoraciones elegantes. Su consistencia permite la aplicación de colores uniformemente sin pérdida de intensidad

                        CMC Endurecedor El CMC (CarboxiMetilCelulosa) es un polvo fino de color blanco amarillento, no tiene olor y se usa en pequeñas cantidades como espesante o estabilizante para algunos productos alimenticios.
                        </p>


                    </div>
                </div>

                <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
                    <div className="how-bor1 ">
                        <div className="hov-img0">
                            <img src={fondant} alt="IMG" />
                        </div>
                    </div>
                </div> 
            </div>

        </div>
    )
}

export default MateriasPrimas;
