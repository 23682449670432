import DIS1 from '../assets/img/distribuidores/dis_1.jpg';
import DIS2 from '../assets/img/distribuidores/dis_2.jpg';
import DIS3 from '../assets/img/distribuidores/dis_3.jpg';
import DIS4 from '../assets/img/distribuidores/dis_4.jpg';
import DIS5 from '../assets/img/distribuidores/dis_5.jpg';
import DIS6 from '../assets/img/distribuidores/dis_6.jpg';
import DIS7 from '../assets/img/distribuidores/dis_7.jpg';
import DIS8 from '../assets/img/distribuidores/dis_8.jpg';
import DIS9 from '../assets/img/distribuidores/dis_9.jpg';
import DIS10 from '../assets/img/distribuidores/dis_10.jpg';
import DIS11 from '../assets/img/distribuidores/dis_11.jpg';
import DIS12 from '../assets/img/distribuidores/dis_12.jpg';
import DIS13 from '../assets/img/distribuidores/dis_13.jpg';
import DIS14 from '../assets/img/distribuidores/dis_14.jpg';
import DIS15 from '../assets/img/distribuidores/dis_15.jpg';
import DIS16 from '../assets/img/distribuidores/dis_16.jpg';
import DIS17 from '../assets/img/distribuidores/dis_17.jpg';
import DIS18 from '../assets/img/distribuidores/dis_18.jpg';
import DIS19 from '../assets/img/distribuidores/dis_19.jpg';
import DIS20 from '../assets/img/distribuidores/dis_20.jpg';
import DIS21 from '../assets/img/distribuidores/dis_21.jpg';
import DIS22 from '../assets/img/distribuidores/dis_22.jpg';
import DIS23 from '../assets/img/distribuidores/dis_23.jpg';
import DIS24 from '../assets/img/distribuidores/dis_24.jpg';
import DIS25 from '../assets/img/distribuidores/dis_25.jpg';
import DIS26 from '../assets/img/distribuidores/dis_26.jpg';
import DIS27 from '../assets/img/distribuidores/dis_27.jpg';
import DIS28 from '../assets/img/distribuidores/dis_28.jpg';
import DIS29 from '../assets/img/distribuidores/dis_29.jpg';
import DIS30 from '../assets/img/distribuidores/dis_30.jpg';
import DIS31 from '../assets/img/distribuidores/dis_31.jpg';
import DIS32 from '../assets/img/distribuidores/dis_32.jpg';

const data = [
    {
        'name': 'Aguascalientes', 'img': DIS1, 'locations': [{
            'name': 'GRUPO SANTANDER DISTRIBUCION DE MATERIAS PRIMAS',
            'address': 'BLOCK B local 29 Central de Abastos 20280 Aguascalientes, Ags 01 449 971 1139 y 971 1778 / CEL. 44 9107 7966',
            'mail': 'grupo.santander1@hotmail.com',
            'web': ''
        },
        {
            'name': 'LA BALANZA DE ALTAMIRA',
            'address': 'PLATEROS Nº 114 Del Carmen 20050 Aguascalientes, Ags 01 449 145 6728',
            'mail': 'labalanzadealtamira@hotmail.com',
            'web': ''
        },
        {
            'name': 'COMERCIALIZADORA CASTHERR',
            'address': 'JOSEFA O. DE DOMINGUEZ Nº 651-A Barrio de la Salud 20240 Aguascalientes, Ags 01 449 994 0906',
            'mail': 'comercializadoracastherr@yahoo.com.mx',
            'web': ''
        }]
    },
    { 'name': 'Baja California', 'img': DIS2, 'locations': [{ 'name': '', 'address': '', 'mail': '', 'web': '' }] },
    { 'name': 'Baja California Sur', 'img': DIS3, 'locations': [{ 'name': '', 'address': '', 'mail': '', 'web': '' }] },
    { 'name': 'Campeche', 'img': DIS4, 'locations': [{ 'name': '', 'address': '', 'mail': '', 'web': '' }] },
    { 'name': 'Chiapas', 'img': DIS5, 'locations': [
        {
            'name': 'DIMAPSA',
            'address': 'CARR. TUXTLA - BERRIOZABAL KM-12 S/N Ciudad Maya 29130 Berriozábal, Chis 01 961 656 1182',
            'mail': 'alisur08@prodigy.net.mx',
            'web': 'www.dimapsa.com.mx'

        },
        {
            'name': 'LA ALIANZA',
            'address': 'AV. PERIFERICO PONIENTE NORTE Nº 31 De Mexicanos 29240 San Cristóbal de las Casas, Chis 01 96 7112 0829',
            'mail': 'alianza_comercializadora@hotmail.com',
            'web': ''
        },
        {
            'name': 'MAPRISA',
            'address': 'CARR. PANAMERICANA KM. 1168 Nº 5 P.B. Jardines del Carmen 29260 San Cristóbal de las Casas, Chis 01 967 678 1930',
            'mail': 'gerencia.compras.mayoreo@prosur.com.mx',
            'web': ''
        }
    ] },
    { 'name': 'Chihuahua', 'img': DIS6, 'locations': [
        {
            'name': 'TODO PARA PANADERIA',
            'address': 'IGNACIO ALLENDE Nº 3 Centro SCT 31091 Chihuahua, Chih 01 614 420 1680',
            'mail': 'materias_primas@hotmail.com',
            'web': ''

        },
        {
            'name': 'PROVEEPAN',
            'address': 'DELICIAS Nº 6541 Nuevo Hipódromo 32685 Juárez, Chih 01 656 664 7124 al 27',
            'mail': '',
            'web': 'www.proveepan.com'
        }
    ] },
    { 'name': 'Coahuila', 'img': DIS7, 'locations': [
        {
            'name': 'MATERIAS PRIMAS EL ARCA',
            'address': 'BLVD. LUIS ECHEVERRIA ALVAREZ Nº 686 Lourdes 25070 Saltillo, Coah 01 844 417 2244',
            'mail': 'tuliorangel@gmail.com',
            'web': ''

        },
        {
            'name': 'PANIFICADORA IDEAL',
            'address': 'CIPRECES Nº 999 Torreón Jardín 27200 Torreón, Coah 01 871 731 8162',
            'mail': 'panificadoralaideal@yahoo.com.mx',
            'web': ''
        }
    ] },
    { 'name': 'Colima', 'img': DIS8, 'locations': [{ 'name': '', 'address': '', 'mail': '', 'web': '' }] },
    { 'name': 'Ciudad de México', 'img': DIS9, 'locations': [
        {
            'name': 'CASA ZARATE',
            'address': 'MESONES Nº 44 Centro (Area 8) 06080 Cuauhtemoc, CDMX 01 55 5709 5151',
            'mail': 'casa_zarate@prodigy.net.mx',
            'web': ''

        },
        {
            'name': 'FRARMEX',
            'address': 'CARUSO Nº 279 Vallejo 07870 Gustavo A. Madero, CDMX 01 55 5759 2120 Y 01 55 5759 2133',
            'mail': 'frivera@frarmex.com',
            'web': ''
        },
        {
            'name': 'SIGLO XXI',
            'address': 'AV. EJE 5 SUR Nº 1300 Ejército de Agua Prieta 09578 Iztapalapa, CDMX 01 55 5773 7813',
            'mail': 'figuerpan@yahoo.com.mx',
            'web': ''
        },
        {
            'name': 'MATERIAS PRIMAS JEAN.CARLO',
            'address': 'QUETZALCOATL Nº 62 Tlaxpana 11370 Miguel Hidalgo, CDMX CEL.55 3033 5212 / 55 1803 0452',
            'mail': 'j.carloss2012@hotmail.com',
            'web': ''
        }
    ] },
    { 'name': 'Durango', 'img': DIS10, 'locations': [
        {
            'name': 'COMERCIAL IBHER',
            'address': 'INDUSTRIAL Nº 506 J Guadalupe Rodríguez 34280 Durango, Dgo 01 618 812 0474',
            'mail': 'e_giovanka@hotmail.com',
            'web': ''

        }
    ] },
    { 'name': 'Estado de México', 'img': DIS11, 'locations': [
        {
            'name': 'COMERCIAL IBHER',
            'address': 'INDUSTRIAL Nº 506 J Guadalupe Rodríguez 34280 Durango, Dgo 01 618 812 0474',
            'mail': 'e_giovanka@hotmail.com',
            'web': ''
        },
        {
            'name': 'GAMA PRODUCTOS PARA PANIFICACION',
            'address': 'VALLE DE VOLGA M. 507 L 925 Valle de Aragón 57100 Netzahualcóyotl, Méx 01 55 5120 2433',
            'mail': 'elomar007@hotmail.com',
            'web': ''
        },
        {
            'name': 'EL RINCON',
            'address': 'ENRIQUE CARNEADO Nº 801 Granjas 50120 Toluca, Méx 01 722 217 7742',
            'mail': 'aviosparapan_elrincon@hotmail.com',
            'web': ''
        },
        {
            'name': 'TODO PARA EL PANADERO Y PASTELERO',
            'address': 'CENTRAL DE ABASTOS TULTITLAN BOD. 32 Nativitas 54900 Tultitlán, Méx 01 55 2451 3407',
            'mail': 'agarciasua70@hotmail.com',
            'web': ''
        },
        {
            'name': 'COMERCIALIZADORA MOZIKI',
            'address': 'VIVEROS DE TLALNEPANTLA Nº 39 - A Real del Ocho 54080 Tlalnepantla, Méx 01 55 2593 2261 / 01 55 4444 6359',
            'mail': 'c_moziki@hotmail.com',
            'web': ''
        },
        {
            'name': 'DISTRIBUIDORA LOS TOKYS',
            'address': 'JILOTEPEC Nº 362 Jesús García Corona 54030 Tlalnepantla, Méx 01 55 5565 3046',
            'mail': 'amartinez362@hotmail.com',
            'web': ''
        },
        {
            'name': 'DISTRIBUIDORA DE AZUCAR MENDOZA',
            'address': 'KM 4.5 CARR TOLUCA - NAUCALPAN NAVE M San Salvador 50200 Toluca, Méx 01 722 210 0104',
            'mail': 'cpsguz2014@hotmail.com',
            'web': ''
        },
        {
            'name': 'SANTA CECILIA',
            'address': 'AV.JOSE LOPEZ PORTILLO Nº 425 LT.J El Balcón 50010 Toluca, Méx 01 722 210 0125 / 01 722 492 9045',
            'mail': 'marpizetha@terra.com',
            'web': ''
        },
        {
            'name': 'PANCRECE',
            'address': 'BLVD.IGNACIO ZARAGOZA MZ. 38 El Mirador 52915 Atizapán de Zaragoza, Méx 01 55 5820 7944',
            'mail': 'compras@pancrece.com',
            'web': ''
        },
        {
            'name': 'DIALPA SAN PABLO',
            'address': 'HACIENDA LA FLORESTA Nº 19 Jardines del Alba 54750 Cuautitlán Izcalli, Méx 01 55 5881 2294',
            'mail': 'compras@pancrece.com',
            'web': ''
        },
        {
            'name': 'PRODUCTOS DE CALIDAD LA REINA',
            'address': 'AV.R - 1 L - 1 MZ.3 Faja de Oro 55490 Ecatepec, Méx 01 55 2232 6804',
            'mail': 'mungothobby@gmail.com',
            'web': ''
        }
    ] },
    { 'name': 'Guanajuato', 'img': DIS12, 'locations': [
        {
            'name': 'LA CONCHA LEON',
            'address': 'BLVD. ANTONIO MADRAZO Nº 217-A Centro Bodeguero Las Trojes 37227 León, Gto 01 477 716 7681',
            'mail': 'ventasleon@laconcha.com.mx',
            'web': 'www.laconcha.com.mx'

        }
    ] },
    { 'name': 'Guerrero', 'img': DIS13, 'locations': [
        {
            'name': 'BALCORY',
            'address': 'EMILIANO ZAPATA Nº 23 Alta Cuauhtémoc 39550 Acapulco, Gro CEL. 74 4430 5149',
            'mail': 'balcory.ventas@gmail.com',
            'web': ''

        }
    ] },
    { 'name': 'Hidalgo', 'img': DIS14, 'locations': [
        {
            'name': 'ESPIGA DE ORO',
            'address': 'CALLE 21 DE MARZO NORTE Nº 1200 Vicente Guerrero 43630 Tulancingo, Hgo 01 775 754 3743',
            'mail': 'espigadeoro17@yahoo.com.mx',
            'web': ''

        }
    ] },
    { 'name': 'Jalisco', 'img': DIS15, 'locations': [
        {
            'name': 'ALMACENES DE DROGAS LA PAZ',
            'address': 'AV. ESPAÑA Nº 1792-A Moderna 44190 Guadalajara, Jal 01 33 3812 4444',
            'mail': 'almacendedrogas@prodigy.net.mx',
            'web': ''

        },
        {
            'name': 'LA CONCHA GUADALAJARA',
            'address': 'AV. DEL BOSQUE Nº 1189 Felipe Ángeles 45590 San Pedro Tlaquepaque, Jal 01 477 716 7681',
            'mail': '',
            'web': 'www.laconcha.com.mx'
        },
        {
            'name': 'PRODUCTOS Y ACCESORIOS PARA REPOSTERIA',
            'address': 'ALDANACA N° 226 Versalles 48310 Puerto Vallarta, Jal 01 322 224 7739',
            'mail': 'reposteria.saborami@gmail.com',
            'web': ''
        }
    ] },
    { 'name': 'Michoacán', 'img': DIS16, 'locations': [
        {
            'name': 'LA FRONTERA MATERIAS PRIMAS Y EQUIPOS PARA LA INDUSTRIA DE ALIMENTOS',
            'address': 'ABASOLO Nº 727 CENTRO HISTÓRICO 58000 Morelia, Mich 01 443 317 8445',
            'mail': 'lafrontera.ventas@gmail.com',
            'web': 'www.lafrontera.mx'

        }
    ] },
    { 'name': 'Morelos', 'img': DIS17, 'locations': [{ 'name': '', 'address': '', 'mail': '', 'web': '' }] },
    { 'name': 'Nayarit', 'img': DIS18, 'locations': [{ 'name': '', 'address': '', 'mail': '', 'web': '' }] },
    { 'name': 'Nuevo León', 'img': DIS19, 'locations': [
        {
            'name': 'FILTROS Y ACCESORIOS',
            'address': 'PASEO DE LOS LEONES Nº 156 Mitras Centro 64460 Monterrey, NL 01 81 8333 4760',
            'mail': 'filtrosyaccesoriosmty@gmail.com',
            'web': ''

        }
    ] },
    { 'name': 'Oaxaca', 'img': DIS20, 'locations': [{ 'name': '', 'address': '', 'mail': '', 'web': '' }] },
    { 'name': 'Puebla', 'img': DIS21, 'locations': [
        {
            'name': 'CIRSA',
            'address': 'AV. DEL CONDE Nº 8 Central de Abastos 72019 Puebla, Pue 01 222 288 0341',
            'mail': 'cfd@cyrsamx.com',
            'web': ''

        },
        {
            'name': 'EL CISNE DE PUEBLA',
            'address': '3 NORTE Nº 609 Centro 72000 Puebla, Pue 01 22 2232 5669',
            'mail': 'elcisnedepuebla_sadecv@yahoo.com.mx',
            'web': ''
        },
        {
            'name': 'ALIMENTOS Y AVIOS ZUANEI',
            'address': '1A CIRCUITO INTERIOR NORTE NAVE F Central de Abastos 72019 Puebla, Pue 01 22 2288 6657',
            'mail': 'zuaneiventas@yahoo.com',
            'web': ''
        },
        {
            'name': 'ABARROTES MARSA',
            'address': 'PRIVADA HIDALGO Nº 2 Teziutlán Centro 73800 Teziutlán, Pue 01 23 1313 1511',
            'mail': 'marsa_614@hotmail.com',
            'web': ''
        }
    ] },
    { 'name': 'Querétaro', 'img': DIS22, 'locations': [
        {
            'name': 'EL SURTIDOR',
            'address': 'ISLAS ALEUTIANAS Nº 190 Bella Vista 76087 Querétaro, Qro 01 442 222 7711',
            'mail': 'elsurtidor_compras@yahoo.com.mx',
            'web': ''

        },
        {
            'name': 'MPPD',
            'address': 'NAVE A LOCAL 18 Plazas del Sol 76099 Querétaro, Qro 01 442 212 7265',
            'mail': 'yadira@materiasprimas.com',
            'web': ''
        }
    ] },
    { 'name': 'Quintana Roo', 'img': DIS23, 'locations': [
        {
            'name': 'PURA CEPA',
            'address': 'ACCESO MZA 61 LT 61 BOD. 22 Supermanzana 299 77560 Benito Juárez, QRoo 01 998 886 2952 /CEL. 99 8222 8443',
            'mail': 'somarpech@hotmail.com recepcion@pcie.com.mx',
            'web': ''

        }
    ] },
    { 'name': 'San Luis Potosi', 'img': DIS24, 'locations': [
        {
            'name': 'PEALPAN',
            'address': 'CORDILLERA HIMALAYA Nº 640 Lomas 4a Secc 78210 San Luis Potosí, SLP 01 444 825 6884',
            'mail': 'ventas@pealpan.com.mx',
            'web': ''

        },
        {
            'name': 'PROPACK MEXICANA',
            'address': 'JARDIN ZACATECAS Nº 27 Cerrada de Roma 78433 Soledad de Graciano Sánchez, SLP 01 444 854 5566',
            'mail': 'propackmexicana@hotmail.com',
            'web': ''
        }
    ] },
    { 'name': 'Sinaloa', 'img': DIS25, 'locations': [
        {
            'name': 'MATERIAS PRIMAS DEL PACIFICO HIDALGO',
            'address': 'Nº 120 OTE.Primer Cuadro(Centro) 81200 Ahome, Sin 01 66 8818 1272',
            'mail': 'mppcomprasmochis@hotmail.com',
            'web': ''
        },
        {
            'name': 'CHANTILLY CULIACAN',
            'address': 'RAFAEL BUELNA Nº 28 Centro 80000 Culiacán, Sin 01 667 713 1320',
            'mail': 'chantilly_mzt@hotmail.com',
            'web': ''
        },
        {
            'name': 'MATERIAS PRIMAS DEL PACIFICO',
            'address': 'CUAUHTEMOC Nº 288 Centro 81000 Guasave, Sin 01 668 818 1272',
            'mail': '',
            'web': ''
        },
        {
            'name': 'CHANTILLY MAZATLAN',
            'address': 'EJERCITO MEXICANO Nº 2004 Insurgentes 82018 Mazatlán, Sin 01 669 986 6111',
            'mail': 'chantilly_mzt@hotmail.com',
            'web': ''
        },
        {
            'name': 'MATERIAS PRIMAS DEL PACIFICO',
            'address': 'JAVIER MINA Nº 327 Zona Centro 81400 Salvador Alvarado, Sin 01 66 8818 1272',
            'mail': '',
            'web': ''
        }
    ] },
    { 'name': 'Sonora', 'img': DIS26, 'locations': [
        {
            'name': 'MATERIAS PRIMAS DEL PACIFICO',
            'address': 'IGNACIO ZARAGOZA Nº 304 Urbanizable 3 85000 Cajeme, Son 01 668 818 1272',
            'mail': '',
            'web': ''
        },
        {
            'name': 'MATERIAS PRIMAS DEL PACIFICO',
            'address': 'BLVD.SOLIDARIDAD Nº 605 - 6 Santa Isabel 83130 Hermosillo, Son 01 668 818 1272',
            'mail': '',
            'web': ''
        },
        {
            'name': 'MATERIAS PRIMAS DEL PACIFICO',
            'address': 'AV.IGNACIO LOPEZ RAYON Nº 204 Álamo 85830 Navojoa, Son 01 668 818 1272',
            'mail': '',
            'web': ''
        }

    ] },
    { 'name': 'Tabasco', 'img': DIS27, 'locations': [
        {
            'name': 'DULCE FANTASIA',
            'address': 'FRANCISCO JAVIER MINA S/N Portal Del Agua 86000 Villahermosa, Tab 01 993 314 1035',
            'mail': 'correodulcefantasia@hotmail.com',
            'web': ''

        }
    ] },
    { 'name': 'Tamaulipas', 'img': DIS28, 'locations': [
        {
            'name': 'PROVEEDORA DEL PASTELERO',
            'address': 'ALTAMIRA Nº 1204 OTE Tampico Centro 89000 Tampico, Tams 01 833 214 1044',
            'mail': 'provpast@prodigy.net.mx',
            'web': ''

        }
    ] },
    { 'name': 'Tlaxcala', 'img': DIS29, 'locations': [
        {
            'name': 'LA CASA DEL PANADERO CALPE',
            'address': 'IGNACIO PICASO SUR Nº 406 Centro 90800 Santa Ana Chiautempan, Tlax 01 24 645 82180',
            'mail': 'calpe@cyrsamx.com',
            'web': ''

        }
    ] },
    { 'name': 'Veracruz', 'img': DIS30, 'locations': [
        {
            'name': 'DIALPA SAN PABLO',
            'address': 'ADOLFO LOPEZ MATEOS Nº 5 Rafael Lucio 91315 Rafael Lucio, Ver 01 228 811 1282',
            'mail': 'dialpa_laz@yahoo.com.mx',
            'web': ''
        },
        {
            'name': 'CEPASA',
            'address': 'PENSADOR MEXICANO(ESQ.ALFA Y OMEGA) S / N Miguel Ángel de Quevedo 91860 Veracruz, Ver 01 22 3397 4710 / 01 22 9934 8690',
            'mail': 'cepasacfds@hotmail.com',
            'web': ''
        },
        {
            'name': 'LA CASA DEL PASTELERO',
            'address': 'URSULO GALVAN Nº 321 - B Centro 91700 Veracruz, Ver 01 22 8818 5271',
            'mail': 'cherry@live.com.mx',
            'web': ''
        },
        {
            'name': 'SURTIPAN',
            'address': 'AV.CUAUHTEMOC Nº 50 21 de Marzo 91010 Xalapa, Ver 01 22 8318 6386',
            'mail': 'surtipanxalapa@gmail.com',
            'web': ''
        },
        {
            'name': 'MATERIAS PRIMAS CAPRICHO',
            'address': 'POETA JESUS DIAZ Nº 4 BIS Centro 91000 Xalapa, Ver 01 22 8818 2018',
            'mail': 'caprichospasteleros@hotmail.com',
            'web': ''
        },
        {
            'name': 'DISTRIBUIDORA SAN MARCOS',
            'address': 'CALLE 8 Nº 102 Fortín de las Flores Centro 94470 Fortín de las Flores, Ver 01 271 405 7537',
            'mail': 'm - mendez17@hotmail.com',
            'web': ''
        },
        {
            'name': 'PASTELERO.COM',
            'address': 'AV.ORIENTE 20 Nº 1 Orizaba Centro 94300 Orizaba, Ver 01 272 106 9077',
            'mail': 'pastelero.com@hotmail.com',
            'web': ''
        }
    ] },
    { 'name': 'Yucatán', 'img': DIS31, 'locations': [
        {
            'name': 'COMEPAN DEL SURESTE',
            'address': 'CALLE 69 por 78 y 80 Nº 590 - A Centro 97000 Mérida, Yuc 01 999 148 6439',
            'mail': 'gaby@comepan.com',
            'web': ''
        },
        {
            'name': 'CASA SANTOS LUGO',
            'address': 'TABLAJE 31247 ANILLO PERIFERICO KM. 46.1 Polígono Tixcacal 97300 Mérida, Yuc 01 999 341 0306',
            'mail': 'www.santoslugo.com.mx',
            'web': ''
        },
        {
            'name': 'LA CASA DEL REPOSTERO',
            'address': 'CALLE 72 Nº 419 Jardines de San Sebastián 97000 Mérida, Yuc 01 999 925 2761',
            'mail': 'casarepocont@prodigy.net.mx',
            'web': ''
        },
        {
            'name': 'BR DISTRIBUCIONES',
            'address': 'CALLE 11 Nº 332 Santa Gertrudis 97300 Mérida, Yuc 01 999 941 8434 / 35',
            'mail': 'acc_bastarrachea@hotmail.com',
            'web': ''
        },
        {
            'name': 'SUPER SAN VALENTIN',
            'address': 'CALLE 51(ENTRE 50 Y 52) Nº 204 Tekax de Álvaro Obregón 97970 Tekax,.Yuc 01 997 974 0937',
            'mail': 'sanvalentin_68@hotmail.com',
            'web': ''
        }
    ] },
    { 'name': 'Zacatecas', 'img': DIS32, 'locations': [
        {
            'name': 'PRODUCTOS TO-PAN',
            'address': 'PROL. ESMERALDA Nº 61-B CTM Magisterial 99320 Jerez,.Zac 01 494 945 1190',
            'mail': 'topan2prod@hotmail.com',
            'web': ''
        }
    ] },
]

export default data;